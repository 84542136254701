import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Logo from '../../assets/images/mint_button_logo.png'
import LogoLight from '../../assets/images/mint_button_logo_light.png'
import { Info, ShowToast, Warning } from "../Toast";
import { ethers } from "ethers";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from "../../utils/wallets/useWeb3ReactDeriw";
import { useSelector } from "react-redux";
import { getContract } from "../../config/contracts";
import { callContract, contractFetcher } from "../../utils/contracts";
import MintAirdropABI from "../../contracts/abi/MintAirdrop.json";
import ReaderABI from "../../contracts/abi/ReaderABI.json";
import { useChainId } from "../../utils/chains";

const ToastId = "Toast_Transaction"

const MintButton = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const rout = useLocation();

    const { chainId } = useChainId()
    const mode = useSelector(state => state.mode);

    const [minting, setMinting] = useState(false)
    
    const { account, library } = useWeb3ReactDeriw()
    // const { account, library } = useWeb3React()
    const contractAddress = getContract(chainId, "MINT_AIRDROP");
    const readerAddress = getContract(chainId, "Reader");
    const [userInfoData, setUserInfoData] = useState({})
    // useEffect(async () => {
    //     getPageData()
    // }, [account])

    // const getPageData = async () => {

    //     if (account) {
    //         const contract = new ethers.Contract(contractAddress, MintAirdropABI, library.getSigner());
    //         const userInfo = await contract.getUserInfo(account)
    //         setUserInfoData(userInfo)
    //     }
    // }

    const mint = async () => {
        if (!account) {
            ShowToast(ToastId, t('提示'), Warning, [t('无法获取地址')])
            return
        }
        const contract = new ethers.Contract(contractAddress, MintAirdropABI, library.getSigner());
        const userInfo = await contract.getUserInfo(account)
        if (userInfo.lastTime.gt(0)) {
            ShowToast(ToastId, t('提示'), Warning, [t('已经领取')])
            return
        }
        setMinting(true)
        ShowToast(ToastId, t('提示'), Warning, [t('铸造中')])
        
        callContract(chainId, contract, "claim", [], {
            pendingMsg: t(`铸造中`) + "...",
            sentMsg: t(`铸造中`) + "...",
            failMsg: t(`铸造失败`),
            successMsg: t(`mint_success`),
            hideSuccessMsg: false,
            gasless: true,
        }, null, contractAddress, MintAirdropABI).then(async (respond) => {
            if (respond && respond.wait) {
                await respond.wait()
            }
            // getPageData()
        }).catch((e) => {
            console.error("e", e)
        }).finally(() => {
            setMinting(false)
        })
    }

    if (!account) {
        return <></>
    }

    // if (userInfoData.lastTime && userInfoData.lastTime.gt(0)) {
    //     return <></>
    // }

    return (
        <div className="home_mint_button" onClick={mint}> <img
            src={mode.mode == 'dark' ? Logo : LogoLight}
            alt=""
            className=""
        />{t('测试代币')}</div>
    );
};

export default MintButton;
