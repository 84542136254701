
import { ethers } from "ethers";
import { DERIWTESTNET, MAINNET, TESTNET, DERIWDEVNET } from "./chains";

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  [MAINNET]: {
    // bsc mainnet
    // Treasury: "0xa44E7252a0C137748F523F112644042E5987FfC7",
    // BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    // GMT: "0x99e92123eB77Bc8f999316f622e5222498438784",
    // Vault: "0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B",
    // Router: "0xD46B23D042E976F8666F554E928e0Dc7478a8E1f",
    // Reader: "0x087A618fD25c92B61254DBe37b09E5E8065FeaE7",
    // AmmFactory: "0xBCfCcbde45cE874adCB698cC183deBcF17952812",
    // AmmFactoryV2: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
    // OrderBook: "0x1111111111111111111111111111111111111111",
    // OrderBookReader: "0x1111111111111111111111111111111111111111",
    // GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    // USDG: "0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7",
    // NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    // XGMT: "0xe304ff0983922787Fd84BC9170CD21bF78B16B10",
    // GMT_USDG_PAIR: "0xa41e57459f09a126F358E118b693789d088eA8A0",
    // XGMT_USDG_PAIR: "0x0b622208fc0691C2486A3AE6B7C875b4A174b317",
    // GMT_USDG_FARM: "0x3E8B08876c791dC880ADC8f965A02e53Bb9C0422",
    // XGMT_USDG_FARM: "0x68D7ee2A16AB7c0Ee1D670BECd144166d2Ae0759",
    // USDG_YIELD_TRACKER: "0x0EF0Cf825B8e9F89A43FfD392664131cFB4cfA89",
    // XGMT_YIELD_TRACKER: "0x82A012A9b3003b18B6bCd6052cbbef7Fa4892e80",
    // GMT_USDG_FARM_TRACKER_XGMT: "0x08FAb024BEfcb6068847726b2eccEAd18b6c23Cd",
    // GMT_USDG_FARM_TRACKER_NATIVE: "0xd8E26637B34B2487Cad1f91808878a391134C5c2",
    // XGMT_USDG_FARM_TRACKER_XGMT: "0x026A02F7F26C1AFccb9Cba7C4df3Dc810F4e92e8",
    // XGMT_USDG_FARM_TRACKER_NATIVE: "0x22458CEbD14a9679b2880147d08CA1ce5aa40E84",
    // AUTO: "0xa184088a740c695E156F91f5cC086a06bb78b827",
    // AUTO_USDG_PAIR: "0x0523FD5C53ea5419B4DAF656BC1b157dDFE3ce50",
    // AUTO_USDG_FARM: "0xE6958298328D02051769282628a3b4178D0F3A47",
    // AUTO_USDG_FARM_TRACKER_XGMT: "0x093b8be41c1A30704De84a9521632f9a139c08bd",
    // AUTO_USDG_FARM_TRACKER_NATIVE: "0x23ed48E5dce3acC7704d0ce275B7b9a0e346b63A",
    // GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    // XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    // GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    // XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  [TESTNET]: {
    // bsc testnet
    Vault: "0x396B62A507ebD345965Fd32c6f65a5d722B47034",
    Router: "0xf675bC08EC46Ab173777e243cDdA706b6C15dAEd",
    VaultUtils: "0xf0603C14cCE7E2d10c81e4E05b8F6Bb3cBfbd8FA",
    VaultReader: "0x3863636836b2d989C8E4d39AbF9526ebaB618Cd0",
    Reader: "0x3D63dd0101f760eaF5DD8FeB2ff072bC2DE22b54",
    GlpManager: "0xb05f5B3e7C8E1F751dfEA8d04D3bF880e7DD2df2",
    // RewardRouter: "0xC40a9c2e7945CA18115aeeF853eE92700a2599A9",
    GlpRewardRouter: "0x06B395f23f1a381AddbB2ec3E0790f3ed7613C28",
    // RewardReader: "0xD7076f43c11BCD449Cd70cC8057f6d810Bff7346",
    NATIVE_TOKEN: "0x5B3D0D6A54D6cD2934C44bd0fe76384780fc0678", // (WETH)
    GLP: "0x85a8daadd566da7d2143f4CC51FE6F1E4F760240",
    // GMX: "0x566ed10fa0D6F4f602f6F2a0127925D9d3Cce5e6",
    // ES_GMX: "0x72cfbc7CF55300bb724563e2CE784b212dB79914",
    // BN_GMX: "0x6032024060841e2177A076cdA6bc8c15fBd5F64f",
    USDG: "0x9236F84cD9385954fc71b5FDD2A5B4Cbce8Aa7AB",
    // ES_GMX_IOU: "0x709C390003264D85b173803e560F6EbB59915064",
    // StakedGmxTracker: "0xa36a09A489316d43B68DD14d42401d5c820c669e",//(sGMX)
    // BonusGmxTracker: "0x3060661BCc5B91389917E2DF00e783E1696828C0",//(sbGMX)
    // FeeGmxTracker: "0x7a505dF14C9d31EFB42fB653CE72e9a3578b2c1c",//(sbfGMX)
    // StakedGlpTracker: "0xA082d162Df73d412DE4c1820EED4aa5a1eE30F34",//(fsGLP)
    // FeeGlpTracker: "0x02471426142D929E1Ada1d937136E3DdcE4039B2",//(fGLP)

    // StakedGmxDistributor: "0xBEDf89880b8871f78F8ec06c4e749b3d983a0B3f", //(EsGMX- sGMX)
    // StakedGlpDistributor: "0xD0F37D02a52E51576335B1C8037EC328A91503f4", //(EsGMX - fsGLP)

    // GmxVester: "0x20b3480A314349652bA321d5CdC6113a333AC676",
    // GlpVester: "0x137D665A7aFC02969C6e2498a0768C9B1792DA4d",
    OrderBook: "0x06DA0D012555aAc2507862C1532b9B9e0B92fAAd",
    // OrderExecutor: "0x572E32E8344e3834059fbDC7197518151896071f",
    OrderBookReader: "0xE7bE451fBC429f1f9457dA6CecdcF39Ae6551F3A",

    PositionRouter: "0xC820ebbE940dd08FCC67BA10f0d1713D62307542",
    // PositionManager: "0xB8dEdD1476f2d0754F3975D9a973Cc2cAC361D63",

    // UniswapGmxEthPool: "0xDb7943Be9f9D59669Ab3B6f1727B41c83ce4F711",
    ReferralStorage: "0x32Fe5aE0464a124E1586178eD29BaAf2120fd4c8",
    ReferralReader: "0xff6f01Bf6eec55e9EbB6dc672db20E65A087AB77",
    Timelock: "0xC91cDE48b9372D5866a04B04a3DEe047f95378dC",
    // Multicall: "0x0DA51b32fF449020D4Cb5A9C965A463957EF2cfE",

    // FundRouter: "0x77cb9892e56e96F185853c00209b09b6d0EaFFc6", // FoundRouterV1
    // FundFactory: "0x19DEE88Dd08e39946ec4AfBc65C641dD34F30b7E", //v1

    Slippage: "0x829bF93DC462F7cA7457822CF5661e79416CEF7C",

    ShortsTracker: "0x3d997b9CCB7C32B4d9CEF2F5ac617a26A281B8E9",
    VaultPriceFeed: "0x486dA2a4EAfD7e9aE9b10cdB3F2FCC388b0a9B18",
    VaultErrorController: "0xd9986BBC2301a12011E60252fbF33594Aa582181",
    Phase: "0x824BeD8D9165038D5E1ac42B8cd377C75604Ad02",

    AuthV2: "0xFD20d566eF048437E98dF8e000fa256f86318E25",
    FoundFactoryV2: "0x1eE334F906422666d2993A706611c302b8938378",
    PoolDataV2: "0x85E40DC8f3Ad7e7ef1CC99dF3c9ecAA4A924e569",
    LpCounter: "0x3379CBC9528f7242c2D856C7518583d39481a8e8",
    ErrorContractV2: "0xC64E54B42EFF7249F5AfC2Dd25351Ca5511231D2",
    FoundRouterV2: "0x5b2E016d5d9F73f8B8679FcEc85B4Ce2C8e6022b",
    FoundReader: "0xf48Ee3edE9ce70c149F688F2F7dD63Fcd1d1DdAe",

    // original
    // AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
    // AmmFactoryV2: "0x1111111111111111111111111111111111111111",
    // GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    // GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
    // XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
    // GMT_USDG_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
    // XGMT_USDG_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
    // GMT_USDG_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
    // XGMT_USDG_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
    // USDG_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
    // XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
    // GMT_USDG_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
    // GMT_USDG_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
    // XGMT_USDG_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
    // XGMT_USDG_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
    // GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    // XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    // GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    // XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  [DERIWDEVNET]: {
    Vault: "0x5b88aA902e9d3B29089dc270303ADd075694187E",
    Router: "0xF7f4dF1e1D8bF22d993104AFbCD14bA8a0Ebc5D8",
    VaultUtils: "0x00c19508817541e1f5B1fAF1D327657688732Fa7",
    VaultReader: "0xc69f046c4626d4BDFC36021A2F3F5e8D95e358D0",
    Reader: "0x4b4f65412fcf535Ab758dD30c87606eC7976De32",
    GlpManager: "0xa80A698Ee4d61b82167f690cc2B247de8279F93f",
    GlpRewardRouter: "0xE3660EF72d74D7Db69409bdf32f827f56fFB5183",
    NATIVE_TOKEN: "0x0C69833AD4429fd69D11B84Bca45db8Ca44B3eDa", // (WETH)
    GLP: "0x48Da52fbAC98cc04230BD459F88FA58d65E24a2c",
    OrderBook: "0x2c4Ffe637e87D51Ae465986913Dfa9faCc56A761",
    OrderBookReader: "0x8Ff46a0F6C3741D9275332AA88dF95Db6134c814",

    PositionRouter: "0x5C96b24bcCa80f282668a7d605D74fc1b6f36121",
    ReferralStorage: "0x197d346c774454f1c55C64133Dc2Fb3c710D1db0",
    ReferralData: "0x46Eb1e71F466231E5DF57c5f0Bbe52c248eA5EFf",
    ReferralReader: "0x82C4d4aD24F69B265Bbe1a15aCf8aeeF53BA165c",
    Timelock: "0x41Bad19685C2C7E661B33D2d3Cdc4E10ac81f6Bf",

    Slippage: "0x6668De54dE315b51F0B6bC60C17d5EdDf730aC47",

    Phase: "0x8DaA8b31D51613e9C3AD1e7805DC5222Ca249091",

    AuthV2: "0xFE56A3E9b3a188e7a414fdBE394D17D14f0290F9",
    FoundFactoryV2: "0xebF2f134bf206608894ca08f210DE31312D363b6",
    PoolDataV2: "0x83763FE16886eE8A4b536E1BBFC2226db4875cD1",
    LpCounter: "0x00C5841F57104845B035065fE8bEB5e8A669c0c2",
    ErrorContractV2: "0x2995dF062BdD06F760E1aD00b88AD26CF463bec0",
    FoundRouterV2: "0x96a92a229770Be41fAc875d253D6a0428f17b45E",
    FoundReader: "0x8c97fCfdb806Af13788F4b8b314B52c91fbed95c",

    TestAuth: "0x624d2A9561DD68Fd97561AEbE503B4CA8B506DAB",
    TestPrpoFactory: "0xA2E1E977a9Af7Cac1D045529128501979d6BD83F",
    TestPropTradeData: "0xdcB21F3FbD087b0574CBc3C947E6AEaeA25dEbd2",
    TestErrContract: "0xE0CFc0f7Cd1c2Ec7C1C5267D172e99B99CfE8942",
    TestPropTradeRouter: "0xDe9d9F49452e4BFead750cA979438aDfB18e1B87",
  
    L1ToL2Router: "0xE4010ef0C069d6076d2B288259448DB3254a575e",
    L2ToL3Router: "0x45dAFc57B1C208b5d9501107069b27521a5285F2",
    L3ToL2Router: "0xec613af90f2E9F7a3BBfDae58Cb31dfA89A50A24",
    L2ToL1Router: "0xE615ae7a06CE7b352c5076d9d3b12292628EbE09",
    UserL2ToL3Router: "0x777B37BE87EbD4FE600153Dd28aa042a4a9fa976",
    UserL3ToL2Router: "0x786EF0c2053B8c22120feb53F1B24bE98d3dD86e",
    ValidateSign: "0x2bE35be5411Cd978dd4e963f30b3f06E22774020",

    Multicall: "0x0b25A1bEeCead9771A40442Eb56fB8aeb6af003E", // 无

    FundRouter: "0x77cb9892e56e96F185853c00209b09b6d0EaFFc6", // FoundRouterV1 // 无
    FundFactory: "0x19DEE88Dd08e39946ec4AfBc65C641dD34F30b7E", //v1 // 无
    PositionManager: "0xd7D76055cf029f6a8c4dC0125ac0f7Edcad98537", // 无

    UniswapGmxEthPool: "0x0000000000000000000000000000000000000000",// 无
    OrderExecutor: "0xEfce82BEa079Aac4Cf630ca813BB394e58834AD9", // 无
    GMX: "0x4b957d37d84E45611652dC4CefF6B5E739556C6E", // 无
    ES_GMX: "0xfF5FA62D6af76B0f7626b828Cf13Ee73Fb919bF1", // 无
    BN_GMX: "0x7201DbE95ADc4ec408E9522b521C671dA13ef00A",// 无
    USDG: "0x2cA00961FF7DbA882fD1784dcEB5DFc8E0B1bF3c", // 无
    ES_GMX_IOU: "0xfF5FA62D6af76B0f7626b828Cf13Ee73Fb919bF1", // 无
    StakedGmxTracker: "0x83f1d1023f0850ad50e472715a65F51589decF7a",//(sGMX) // 无
    BonusGmxTracker: "0x86e51C5d1F7Ff5bBa9C96815dBe2d90c4F55b6cF",//(sbGMX) // 无
    FeeGmxTracker: "0xf3202E5f7Cb2D78baC6707483305Aaf4780549A2",//(sbfGMX) // 无
    StakedGlpTracker: "0x07A212157174bb21460Acb0a883Eb9aEb6ba8D0F",//(fsGLP) // 无
    FeeGlpTracker: "0x57A3a74f06e497339dcF6203469ef38d05F4fa28",//(fGLP)// 无
    RewardReader: "0x403eC1a2b47fA36977c1AcfD51584De7805b824e", // 无
    RewardRouter: "0x63B1fFfAe2ca512F3C0350bE5D97FbC2718DF70F", // 无

    StakedGmxDistributor: "0x27C35d9Bc92c23116D569CB25e77AF49B208a18c", //(EsGMX- sGMX) // 无
    StakedGlpDistributor: "0x8aE62455A3d9c87ECB9838a8F1D7FD8B6C1956FC", //(EsGMX - fsGLP) // 无

    GmxVester: "0xb4E6D29a5E14498d878662e2e04237ebBD66aE4C", // 无
    GlpVester: "0x27573aB3CD6ca94bdDC06Ec2AdFbf248eE8A8281",// 无

    // original
    AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
    AmmFactoryV2: "0x1111111111111111111111111111111111111111",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
    XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
    GMT_USDG_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
    XGMT_USDG_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
    GMT_USDG_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
    XGMT_USDG_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
    USDG_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
    XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
    GMT_USDG_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
    XGMT_USDG_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
    MINT_AIRDROP: "0xd7f4345449dCBf415C39e5FF4f2B642B915f2753",
  },
  [DERIWTESTNET]: {
    Vault: "0xd8b5450Ec4fa699e1568FE4822b9C6bC44F5e0b1",
    Router: "0xc341cCD15cb8dC4e1020FC06EeF53aCb6010DDE1",
    VaultUtils: "0x33e25a86ACC644F31b560Bb02d5Dc620A0A2d5c7",
    VaultReader: "0x84b982952b15556aF6D7c8ba649Cc35C4A5b2284",
    Reader: "0xa407D100a5EeC6D17795B3dBe868751d1D7FFC6B",
    GlpManager: "0xf7862fEc11a268277D5c0AF34a4770135f6cBAA1",
    GlpRewardRouter: "0x4a822deE5b3AA5B9Df4B5399D7B7234824dBe53e",
    NATIVE_TOKEN: "0xb86b491dA10f9194C0C5c0B29cD1298fAf1A634A", // (WETH)
    GLP: "0x93a9b469F0509adDbeb798b541E12A43bA0d6166",
    OrderBook: "0x05b66aaC83fC4815A4B708A2C57B39d56c46c70b",
    OrderBookReader: "0x1Ca1B417E95A44F59E732DAFa49b698952B04703",

    PositionRouter: "0x9Fb76a6B771B39B1BC138C1e7b4a7a4E2a53cCD4",
    ReferralStorage: "0x964E2c1058eA3D5718E5658C697e687cb823d936",
    ReferralData: "0xc6201D30C99206fB5DFFf38E66EfD3753cc25e10",
    ReferralReader: "0xfcd5ddd1B944910b94Dc232eA68b6bcd33D5c422",
    Timelock: "0x4Eb3bBbA193Fb85e454C663c48bBC46A287810ee",

    Slippage: "0xfa4702Fd62206C6fF1B8Ea6fBb06AEF290506256",

    Phase: "0xc785C470A9ED0B1669375e434A03225797072f09",
    AuthV2: "0xd5D374d7989721aBFC78E862A285652f08DB1DA2",
    FoundFactoryV2: "0xCf1229cD1da55E53527e4d08c04C84c246816410",
    PoolDataV2: "0x4A30e14F493BcBDaB057b0F4883C09a46c98E906",
    LpCounter: "0x606e188632f9A56c05D1179Cf5779B7cd9c7825C",
    ErrorContractV2: "0xB1a7A95707421DD01A304643Da3aE1C2B1bA4997",
    FoundRouterV2: "0x4Ce253Bd5255257AE96DE0Dda3f990b1Ed24e231",
    FoundReader: "0x904150FB2C31a443F88A760F93251FB3150842E8",

    TestAuth: "0x6e6F13D2706D861F539925A5Af18893FB3D1D79C",
    TestPrpoFactory: "0xE9F045f0CE5dc1AD552e20E8df668194d67f95D5",
    TestPropTradeData: "0x305507D45D5441B81F5dD8FF9f00f65e0B392e86",
    TestErrContract: "0x3D343Fc0F6c6D2E047ec5e16e39A9b6A2031B9Ac",
    TestPropTradeRouter: "0x4D778dE09f5C043677bd18888114A9a0911dCE96",
  
    L1ToL2Router: "0x89b80e784F85028C75A00971B1A3f5827DC0d0aa",
    L2ToL3Router: "0xCaC3F06D4Ca1A72047dCf22b672106ABB4C28C9C",
    L3ToL2Router: "0x09167d5D48bd6C95a50cce5E1B163a2017Ea1470",
    L2ToL1Router: "0x0271E369eCD7B520BFad3bFB790e3b9409D0d396",
    UserL2ToL3Router: "0x9D51d0444c454d14b2bF6dE1E0b06D3fAB78C42e",
    UserL3ToL2Router: "0x9bC0eC414A47a41cF77452C2E667F4AfC2183146",
    ValidateSign: "0x6e465324A7d113389De95183Db8Cfb76d411625c",

    Multicall: "0x0b25A1bEeCead9771A40442Eb56fB8aeb6af003E", // 无

    FundRouter: "0x77cb9892e56e96F185853c00209b09b6d0EaFFc6", // FoundRouterV1 // 无
    FundFactory: "0x19DEE88Dd08e39946ec4AfBc65C641dD34F30b7E", //v1 // 无
    PositionManager: "0xd7D76055cf029f6a8c4dC0125ac0f7Edcad98537", // 无

    UniswapGmxEthPool: "0x0000000000000000000000000000000000000000",// 无
    OrderExecutor: "0xEfce82BEa079Aac4Cf630ca813BB394e58834AD9", // 无
    GMX: "0x4b957d37d84E45611652dC4CefF6B5E739556C6E", // 无
    ES_GMX: "0xfF5FA62D6af76B0f7626b828Cf13Ee73Fb919bF1", // 无
    BN_GMX: "0x7201DbE95ADc4ec408E9522b521C671dA13ef00A",// 无
    USDG: "0x2cA00961FF7DbA882fD1784dcEB5DFc8E0B1bF3c", // 无
    ES_GMX_IOU: "0xfF5FA62D6af76B0f7626b828Cf13Ee73Fb919bF1", // 无
    StakedGmxTracker: "0x83f1d1023f0850ad50e472715a65F51589decF7a",//(sGMX) // 无
    BonusGmxTracker: "0x86e51C5d1F7Ff5bBa9C96815dBe2d90c4F55b6cF",//(sbGMX) // 无
    FeeGmxTracker: "0xf3202E5f7Cb2D78baC6707483305Aaf4780549A2",//(sbfGMX) // 无
    StakedGlpTracker: "0x07A212157174bb21460Acb0a883Eb9aEb6ba8D0F",//(fsGLP) // 无
    FeeGlpTracker: "0x57A3a74f06e497339dcF6203469ef38d05F4fa28",//(fGLP)// 无
    RewardReader: "0x403eC1a2b47fA36977c1AcfD51584De7805b824e", // 无
    RewardRouter: "0x63B1fFfAe2ca512F3C0350bE5D97FbC2718DF70F", // 无

    StakedGmxDistributor: "0x27C35d9Bc92c23116D569CB25e77AF49B208a18c", //(EsGMX- sGMX) // 无
    StakedGlpDistributor: "0x8aE62455A3d9c87ECB9838a8F1D7FD8B6C1956FC", //(EsGMX - fsGLP) // 无

    GmxVester: "0xb4E6D29a5E14498d878662e2e04237ebBD66aE4C", // 无
    GlpVester: "0x27573aB3CD6ca94bdDC06Ec2AdFbf248eE8A8281",// 无

    // original
    AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
    AmmFactoryV2: "0x1111111111111111111111111111111111111111",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
    XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
    GMT_USDG_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
    XGMT_USDG_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
    GMT_USDG_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
    XGMT_USDG_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
    USDG_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
    XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
    GMT_USDG_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
    XGMT_USDG_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",

    MINT_AIRDROP: "0x1206f4f4dE5F7B06563880a50B866bB29247AD2e",
  },
};

export function getContract(chainId, name) {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}


// import { ethers } from "ethers";
// import { MAINNET, TESTNET } from "./chains";

// export const XGMT_EXCLUDED_ACCOUNTS = [
//   "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
//   "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
//   "0xa633158288520807f91ccc98aa58e0ea43acb400",
//   "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
// ];

// const CONTRACTS = {
//   [MAINNET]: {
//     // bsc mainnet
//     // Treasury: "0xa44E7252a0C137748F523F112644042E5987FfC7",
//     // BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
//     // GMT: "0x99e92123eB77Bc8f999316f622e5222498438784",
//     // Vault: "0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B",
//     // Router: "0xD46B23D042E976F8666F554E928e0Dc7478a8E1f",
//     // Reader: "0x087A618fD25c92B61254DBe37b09E5E8065FeaE7",
//     // AmmFactory: "0xBCfCcbde45cE874adCB698cC183deBcF17952812",
//     // AmmFactoryV2: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
//     // OrderBook: "0x1111111111111111111111111111111111111111",
//     // OrderBookReader: "0x1111111111111111111111111111111111111111",
//     // GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
//     // USDG: "0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7",
//     // NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
//     // XGMT: "0xe304ff0983922787Fd84BC9170CD21bF78B16B10",
//     // GMT_USDG_PAIR: "0xa41e57459f09a126F358E118b693789d088eA8A0",
//     // XGMT_USDG_PAIR: "0x0b622208fc0691C2486A3AE6B7C875b4A174b317",
//     // GMT_USDG_FARM: "0x3E8B08876c791dC880ADC8f965A02e53Bb9C0422",
//     // XGMT_USDG_FARM: "0x68D7ee2A16AB7c0Ee1D670BECd144166d2Ae0759",
//     // USDG_YIELD_TRACKER: "0x0EF0Cf825B8e9F89A43FfD392664131cFB4cfA89",
//     // XGMT_YIELD_TRACKER: "0x82A012A9b3003b18B6bCd6052cbbef7Fa4892e80",
//     // GMT_USDG_FARM_TRACKER_XGMT: "0x08FAb024BEfcb6068847726b2eccEAd18b6c23Cd",
//     // GMT_USDG_FARM_TRACKER_NATIVE: "0xd8E26637B34B2487Cad1f91808878a391134C5c2",
//     // XGMT_USDG_FARM_TRACKER_XGMT: "0x026A02F7F26C1AFccb9Cba7C4df3Dc810F4e92e8",
//     // XGMT_USDG_FARM_TRACKER_NATIVE: "0x22458CEbD14a9679b2880147d08CA1ce5aa40E84",
//     // AUTO: "0xa184088a740c695E156F91f5cC086a06bb78b827",
//     // AUTO_USDG_PAIR: "0x0523FD5C53ea5419B4DAF656BC1b157dDFE3ce50",
//     // AUTO_USDG_FARM: "0xE6958298328D02051769282628a3b4178D0F3A47",
//     // AUTO_USDG_FARM_TRACKER_XGMT: "0x093b8be41c1A30704De84a9521632f9a139c08bd",
//     // AUTO_USDG_FARM_TRACKER_NATIVE: "0x23ed48E5dce3acC7704d0ce275B7b9a0e346b63A",
//     // GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
//     // XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
//     // GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
//     // XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
//   },
//   [TESTNET]: {
//     // bsc testnet
//     Vault: "0xd678230Cf5c5e6CDCD16c5bc2070aDb9D86753CE",
//     Router: "0x8BcCBB819F53a48FDF58aA53De781bfc0C1eBe4f",
//     VaultUtils: "0x5E5c46EaAFa71dBaC8335d628d74150e26Dec9bB",
//     VaultReader: "0x84684b407372058fEaC3FEC529db210Ac6dEd891",
//     Reader: "0xe67671B61DB3b9C9638FF024f4Ad6cc9C5403584",
//     GlpManager: "0x4D11095a43Cd56121609F7d7a9F1cAcF118082B4",
//     RewardRouter: "0x2bDE6F9C8A267C4f9D0B7e3a262356D0db78E331",
//     GlpRewardRouter: "0x263704c752ee0A1c72Ae250370f20ab031De5E6E",
//     RewardReader: "0xb7e131Dc988e1Dab7587e98c297d654696631DE5",
//     NATIVE_TOKEN: "0x55183E12e5F02d246D64F0D75dC5b84bf239ffAE", // (WETH)
//     GLP: "0xdf6A5eb8D81dDae36e04f260C8Ac31f7F6D7408b",
//     GMX: "0xc6B51289a92A34BC73f74af354b42d76e4E19ba0",
//     ES_GMX: "0xFdEdC88e09bdb3EAf6132c0efd2eB9923F8b0B2b",
//     BN_GMX: "0xFB862F4b4Abc07Dbecded311329e6548A7686Cc6",
//     USDG: "0x45511FB86004C5aE220D0b9078535990BA46E8dF",
//     ES_GMX_IOU: "0x3B3706d287380061d093abB6BF994dC089266002",
//     StakedGmxTracker: "0x0AEf2bf355Fc52D41aCBd3ebF95787EF58FcA242",//(sGMX)
//     BonusGmxTracker: "0x957a815F3C5172Fd7a57e823e4d158B610e9f060",//(sbGMX)
//     FeeGmxTracker: "0x86B49b55BF9EeB1705a9623B1a1EE03Ca3178b8B",//(sbfGMX)
//     StakedGlpTracker: "0x2c0b8D001544d8e1cd0536395A874cB95Ce425da",//(fsGLP)
//     FeeGlpTracker: "0xcCaE489b5cB2b931ac4ec0C6E4e3216Dd3F9Fd5f",//(fGLP)

//     StakedGmxDistributor: "0xda4f857Aa5B30aC94cF8B3B5EcC1Bb8F38430FeD", //(EsGMX- sGMX)
//     StakedGlpDistributor: "0x05cA20b64A1841A9829Fa03675bb40ed56d35493", //(EsGMX - fsGLP)

//     GmxVester: "0xf6Ce4Bcb60Be992fef19d51c5B40EBd265704F78",
//     GlpVester: "0x966234095004A4Df599cEe047dA5a79EDBa477Fe",
//     OrderBook: "0x977C5aDc12C411ea55E3d3A794f4C3932645313D",
//     OrderExecutor: "0x10b442FdE4C20cfE60bA60C7Ea65DaeB3BF5C938",
//     OrderBookReader: "0x72FD33461B7eD5377372054EB33fb3ee229905da",

//     PositionRouter: "0x4feB1601b500422d4a8b6516F9f99a5692E4f9c0",
//     PositionManager: "0x86a74542380b10791E2220332D6Ce6C70ef1C521",

//     UniswapGmxEthPool: "0xEbE9F5b06A2C54128e03A2369e2D89e8eb681253",
//     ReferralStorage: "0x0CA240C6e892f611A2619a07bBc4d5B828AFd1cc",
//     ReferralReader: "0x3Ab205F3696561af4D39bcA089C6148d912cF41F",
//     Timelock: "0xEE86AdB26BCF950bA0734553d7e8ee57a201A639",
//     Multicall: "0x4Ef44CaDAB2F27c0B30E46e240184DCe05048DbA",

//     FundRouter: "0x77cb9892e56e96F185853c00209b09b6d0EaFFc6", // FoundRouterV1
//     FundFactory: "0x19DEE88Dd08e39946ec4AfBc65C641dD34F30b7E", //v1

//     Slippage: "0x6D46653092c73Bd02E68De91C38481DC06bAB9FE",

//     AuthV2: "0x397e27E1D84EdD1CAd0C40749cfa00ED83Eb5De0",
//     FoundFactoryV2: "0x65da02976aD11fed80d3378B8bc5b15edC66c1C8",
//     PoolDataV2: "0x35D66c8f3C17784779263b3307292D618cB358aA",
//     LpCounter: "0xC3613f8ae4b3dc59c1F239578660032a3BE41044",
//     ErrorContractV2: "0x4433540b9427f4D844595fAe5Baa655ff66cc9c9",
//     FoundRouterV2: "0x7918693eEf2b556815DbF32256b51dA01d02D4D8",
//     FoundReader: "0xf8149AE481D4723B5850DbCFB7D4c1829A578e49",

//     // original
//     AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
//     AmmFactoryV2: "0x1111111111111111111111111111111111111111",
//     GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
//     GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
//     XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
//     GMT_USDG_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
//     XGMT_USDG_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
//     GMT_USDG_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
//     XGMT_USDG_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
//     USDG_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
//     XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
//     GMT_USDG_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
//     GMT_USDG_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
//     XGMT_USDG_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
//     XGMT_USDG_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
//     GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
//     XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
//     GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
//     XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
//   },
// };

// export function getContract(chainId, name) {
//   if (!CONTRACTS[chainId]) {
//     throw new Error(`Unknown chainId ${chainId}`);
//   }

//   if (!CONTRACTS[chainId][name]) {
//     throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
//   }

//   return CONTRACTS[chainId][name];
// }
