import { SET_MNEMONIC_CONFIRM } from "./action";

const mode = (state = {is_confirm: true}, action) => {
  if (action.type === SET_MNEMONIC_CONFIRM.type) {
    return action.payload;
  } else {
    return state;
  }
};

export default mode;
