import React from "react";
import { Modal, Drawer } from "antd";

import "./index.scss";
import CloseModel from "../../../assets/images/close-icon.png";
import CloseModelW from "../../../assets/images/close-icon.png";

const CModal2 = ({
  visible,
  down = false,
  onCancel,
  width = 400,
  title,
  children,
  maskClosable = true,
  showClose = true
}) => {

  return <Modal
    className="c-modal2 c-modal2-down"
    open={visible}
    centered
    footer={null}
    onCancel={onCancel}
    closable={false}
    maskClosable={maskClosable}
    width={width}
  >
    {showClose ? (
      <img
        src={CloseModel}
        onClick={onCancel}
        className="c-modal__close"
        alt=""
      />
    ) : (
      ""
    )}

    {title ? (
      <div
        className="c-modal__title"
      >
        {title}
      </div>
    ) : (
      ""
    )}
    {children}
  </Modal>
};

export default CModal2;
