import Web3 from "web3";
import BigNumber from "bignumber.js";
import config from "../config";
import erc20ABI from "./abi/token.json";
import networkConfig from "../config";
import { bigNumberify } from "../utils/numbers";
import { ethers } from "ethers";
import { getGasLimit } from '../utils'
import { decimalBit } from "../utils";
import { formatCoinAmount } from "../utils";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

// eth地址
const ETH_ADDR = networkConfig.mainChainAddr;

class Erc20Service {
  web3;
  erc20Contract;

  balance

  constructor() {
    try {
      if (this.web3) return;
      const web3 = window.web3;
      // // window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.erc20Contract = new this.web3.eth.Contract(
        erc20ABI,
        networkConfig.ismAddr
      );
      this.balance = {}
    } catch (e) {
      console.log(e.message);
    }
  }
  
  // 获取币种的名称
  async getTokenSymbol(tokenAddress) {
    try {
      if (tokenAddress === ETH_ADDR) {
        return "ETH";
      }
      const contract = new this.web3.eth.Contract(erc20ABI, tokenAddress);
      const symbol = await contract.methods.symbol().call();
      return symbol.toUpperCase();
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 获取币种的精度
  async getTokenDecimals(tokenAddress) {
    try {
      if (tokenAddress === ETH_ADDR) {
        return 18;
      }
      const contract = new this.web3.eth.Contract(erc20ABI, tokenAddress);
      return await contract.methods.decimals().call();
    } catch (e) {
      console.log(e);
      return;
    }
  }



  // 获取币种余额
  async getTokenTotalSupply(tokenAddress) {
    const contract = new this.web3.eth.Contract(erc20ABI, tokenAddress);
    const totalSupply = await contract.methods.totalSupply().call();
    return totalSupply;
  }



  // 跨链获取币种信息


  // 跨链获取币种余额
  async getTokenInfoCross(token, fromChainId, toChainId, refBalance, tokenAddress, rpc, mainSymbol, account, toTokenAddress, toRpc, setRerender) {

    const url = new Web3.providers.HttpProvider(rpc);
    const crossRPC = new Web3(url);
    
    const contract = new crossRPC.eth.Contract(
      erc20ABI, tokenAddress
    );
    // const aaa = await contract.methods.symbol().call();
    // console.log('aaa', aaa)
// return
    const toUrl = new Web3.providers.HttpProvider(toRpc);
    const toCrossRPC = new Web3(toUrl);

    const toContract = new toCrossRPC.eth.Contract(
      erc20ABI, toTokenAddress
    );

    let toBalance = "0"
    let toBalanceDec = "0"

    let symbol = ""
    let decimals = 0

    if (token && token.symbol && token.decimal) {
      console.log(111)
      symbol = token.symbol
      decimals = token.decimal
    } else if (toTokenAddress == config.address0) {
      console.log('222', toTokenAddress, config.address0)
      symbol = "ETH"
      decimals = "18"
    } else {
      console.log(333, tokenAddress, rpc)
      console.log('toTokenAddress', toTokenAddress)
      const url = new Web3.providers.HttpProvider(rpc);
    const crossRPC = new Web3(url);
    
    const contract = new crossRPC.eth.Contract(
      erc20ABI, tokenAddress
    );
    console.log('urur', url, crossRPC, contract)
      // symbol = "ETH"
      // decimals = "18"
      // debugger
      symbol = await contract.methods.symbol().call();
      console.log('symbol', symbol)
      decimals = await toContract.methods.decimals().call();
      console.log('decimals', decimals, )
    }
    if (toTokenAddress && toRpc) {
      if (toTokenAddress == config.address0) {
        toCrossRPC.eth.getBalance(account).then((mainBalance) => {
          if (!refBalance[toChainId]) {
            refBalance[toChainId] = {}
          }
          refBalance[toChainId][toTokenAddress] = new BigNumber(mainBalance).div(new BigNumber(10).pow(decimals)).toString()
          setRerender()
          // toBalanceDec = new BigNumber(mainBalance).div(new BigNumber(10).pow(decimals)).toString()
        })
        // const mainBalance = await toCrossRPC.eth.getBalance(account);
        // toBalance = mainBalance
        // toBalanceDec = new BigNumber(mainBalance).div(new BigNumber(10).pow(decimals)).toString()
      } else {
        toContract.methods.balanceOf(account).call().then((balance) => {
          if (!refBalance[toChainId]) {
            refBalance[toChainId] = {}
          }
          refBalance[toChainId][toTokenAddress] = new BigNumber(balance).div(new BigNumber(10).pow(decimals)).toString()
          setRerender()
        })
        // const balance = await toContract.methods.balanceOf(account).call();
        // toBalance = balance
        // toBalanceDec = new BigNumber(balance).div(new BigNumber(10).pow(decimals)).toString()
      }
    }

    if (tokenAddress == config.address0) {
      crossRPC.eth.getBalance(account).then((mainCoin) => {
        if (!refBalance[fromChainId]) {
          refBalance[fromChainId] = {}
        }
        refBalance[fromChainId][tokenAddress] = new BigNumber(mainCoin)
          .div(new BigNumber(10).pow(decimals))
          .toString()
        setRerender()
      })
      // const mainCoin = await crossRPC.eth.getBalance(account);
      return {
        address: tokenAddress,
        toAddress: toTokenAddress,
        decimals: 18,
        symbol: mainSymbol,
        // balance: mainCoin,
        // balanceDec: new BigNumber(mainCoin)
        // .div(new BigNumber(10).pow(decimals))
        // .toString()
      }
    }
    contract.methods.balanceOf(account).call().then((balance) => {
      if (!refBalance[fromChainId]) {
        refBalance[fromChainId] = {}
      }
      refBalance[fromChainId][tokenAddress] = new BigNumber(balance)
        .div(new BigNumber(10).pow(decimals))
        .toString()
      setRerender()
    })
    // const balance = await contract.methods.balanceOf(account).call();
    return {
      address: tokenAddress,
      toAddress: toTokenAddress,
      decimals,
      symbol,
      // toBalanceDec,
      // toBalance,
      // balance,
      // balanceDec: new BigNumber(balance)
      //   .div(new BigNumber(10).pow(decimals))
      //   .toString()
    }
  }

  async checkApproveAmountNewWallet (account, rpc, token, contractAddress) {
    try {

      const url = new Web3.providers.HttpProvider(rpc);
      const web3 = new Web3(url)
      const contract = new web3.eth.Contract(erc20ABI, token);
      const amount = await contract.methods
        .allowance(account, contractAddress)
        .call();
      return amount
    } catch(e) {
      return 0
    }
  }


  // 进行授权
  async approveTokenNewWallet(account,rpc, token, contractAddress) {
    try {
      const web3 = new Web3(window.web3.currentProvider)
      const contract = new web3.eth.Contract(erc20ABI, token);
      const res = await contract.methods.approve(contractAddress, ethers.constants.MaxUint256).send({ from: account, value: '0x0', gasLimit: 221240 })
      return res
    } catch (e) {
      return e;
    }
  }

  // 发送代币
  async sendToke (account,rpc, token, toAddress, amount) {
    try {
      const web3 = new Web3(window.web3.currentProvider)
      const contract = new web3.eth.Contract(erc20ABI, token);
      const res = await contract.methods.transfer(toAddress, amount).send({ from: account, value: '0x0', gasLimit: 221240 })
      return res
    } catch (e) {
      return e;
    }
  }

  // 跨链获取币种余额
  async getBalanceOfNewWallet(account,rpc, token) {
    const url = new Web3.providers.HttpProvider(rpc);
    const web3 = new Web3(url)
    const contract = new web3.eth.Contract(erc20ABI, token);

    const symbol = await contract.methods.symbol().call();
    const decimals = await contract.methods.decimals().call();
    const balance = await contract.methods
      .balanceOf(account)
      .call();

    const ps = ethers.utils.formatUnits(balance, decimals, decimals)
    return {
      symbol,
      decimals,
      balance,
      balanceDecFormat: decimalBit(ps, formatCoinAmount(ps)),
      balanceDec: ps,
    }
  }

  // 创建Erc20Contract
  async getErc20Contract(abi, contractAddress) {
    try {
      const _contract = new this.web3.eth.Contract(abi, contractAddress);
      this.web3.eth.net.givenProvider.chainId = 0x22b8;
      return _contract;
    } catch (e) {
      console.log(e);
    }
  }

}

export default Erc20Service;
