import React, { useState, useEffect } from "react";
import CModal2 from "../common/CModal2";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ViewmneImg from '../../assets/images/viewmne.png'
import CheckSmallImg from '../../assets/images/check-small.png'
import {Checkbox} from 'antd'
import MnemonicModal from "../MnemonicModal";
import "./index.scss";

const forge = require('node-forge');

const ViewMnemonicConfirmModal = props => {
  const {onClose, visible} = props
  const [isCheck, setCheck] = useState(false)
  const { t } = useTranslation();
  const mode = useSelector(state => state.mode);
  const [countDown, setCountDown] = useState(2)
  const [isMnemonicModal, setMnemonicModal] = useState(false)
  const dispatch = useDispatch();
  const confirm = async () => {
    if (countDown > 0 || !isCheck) {
      return
    }
    setMnemonicModal(true)
  }
  useEffect(() => {
    setTimeout(() => {
      if (countDown <= 0) {
        return
      }
      setCountDown(countDown - 1)
    }, 1000)
  }, [countDown])
  return (
    <CModal2
      down={true}
      onCancel={onClose}
      visible={visible}
      width={400}
      title={t('显示密码口令')}
    >
        {
          isMnemonicModal &&
          <MnemonicModal visible={isMnemonicModal} onClose={() => setMnemonicModal(false)} onConfirm={() => {
            setMnemonicModal(false)
            onClose()
          }} type="view" />
        }
        <div className="cancel_confirm_tx_viewmne">
          <div className="confirm_tx_line">
            {t('您的密码口令是一组24个单词，用于备份和访问您的账户。')}
          </div>
            <div className="generate">
              <img src={ViewmneImg} className="generate_loading_img"/>
              <div className="generate_right">
                {t('任何知道您密码口令的人都可以访问您的钱包，从而使您的资产面临风险')}
              </div>
            </div>
          <div className="confirm_tx_line3">
            {
              isCheck ?
              <div className="confirm_tx_line3_nd confirm_tx_line3_nd_act" onClick={() => setCheck(false)}>
                <img src={CheckSmallImg}/>
              </div>
             :
            <div className="confirm_tx_line3_nd" onClick={() => setCheck(true)}/>
            }
            {t('我了解风险，我绝不会与任何人分享我的密码口令')}
          </div>
          <div className={["confirm_tx_btn", !isCheck || countDown > 0 ? 'confirm_tx_btn_dis' : ''].join(' ')} onClick={confirm}>{
          countDown > 0 ?
          t('等待x秒', {x:countDown}) :
          t('确认')}</div>
        </div> 
    </CModal2>
  );
};

export default ViewMnemonicConfirmModal;
