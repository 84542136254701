import "./App.scss";
import Nav from "../src/components/Nav";
import BrowserNotification from "../src/components/BrowserNotification";
import Footer from "../src/components/Footer";
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { useMedia } from "react-use";
import loadable from "./utils/loadable";
import { useSocket } from "./socket";
import { useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from "./utils/wallets/useWeb3ReactDeriw";
import { useLoadTokens } from "./config/token";
import { intervalPost, leave } from "./utils/eventTracking";
import { useSelector, useDispatch } from "react-redux";
import { SET_MNEMONIC } from "./store/mnemonic/action";
import { updateMnemonic } from "./utils";
import { ethers } from "ethers";
import { AES, enc } from 'crypto-js';
import { staticEncryptionKey, staticEncryptionPs } from "./utils/legacy";
import { deriveHDKeyFromEthereumSignature } from "./utils/privateKey";
const forge = require('node-forge');

const Home = loadable(() => import("./pages/home"));
const TransactionParams = loadable(() => import("./pages/transaction"));
const TransactionNone = loadable(() => import("./pages/transactionNone"));
const Earn = loadable(() => import("./pages/earn"));
const Ecology = loadable(() => import("./pages/ecology"));
const RecommendShare = loadable(() => import("./pages/recommend"));
const RecommendSetting = loadable(() => import("./pages/recommendSetting"));
// const FundTransaction = loadable(() => import("./pages/fundTransaction"));
const AboutFundPool = loadable(() => import("./pages/aboutFundPool"));
const EarnMy = loadable(() => import("./pages/earnMy"));
const CreateChallenge = loadable(() => import("./pages/createChallenge"))
const CreateFund = loadable(() => import("./pages/createFund"))
const ChallengeDetail = loadable(() => import("./pages/challengeDetail"))
const Challenge = loadable(() => import("./pages/challenge"))
const CrossChain = loadable(() => import("./pages/crossChain"))
const TradingCompetition = loadable(() => import("./pages/tradingCompetition"))
const Dashboard = loadable(() => import("./pages/dashboard"))
const BountyHunter = loadable(() => import("./pages/bountyHunter"))
const BugCompetition = loadable(() => import("./pages/bugCompetition"))

const App = () => {
  const below960 = useMedia("(max-width: 960px)");
  const { tokens } = useLoadTokens()
  const { account, library } = useWeb3ReactDeriw()
  const dispatch = useDispatch();
  const storeMnemonic = useSelector(state => state.mnemonic)
  // const { account } = useWeb3React()
  // 页面加载完 调用周期调用埋点方法
  // window.addEventListener('load', function () {
  //   intervalPost()

  useEffect(() => {
    const localStorageMnemonic = localStorage.getItem('mnemonic')
    if (localStorageMnemonic) {
      const mnemonic = JSON.parse(localStorageMnemonic)
      const nowTime = Math.trunc(Date.now()/1000)
      if (Number(nowTime) < Number(mnemonic.time) + (24 * 60 * 60)) {
        updateMnemonic(mnemonic)

        const sign1 =  AES.decrypt(mnemonic.sign, staticEncryptionKey).toString(enc.Utf8);
        const password =  AES.decrypt(mnemonic.password, staticEncryptionPs).toString(enc.Utf8);
        var hmac = forge.hmac.create();
        hmac.start('sha256', password);
        hmac.update(sign1);
        const hex = hmac.digest().toHex()
        const mon = deriveHDKeyFromEthereumSignature(hex)
        dispatch({ type: SET_MNEMONIC.type, payload: {...mon, mnemonicList: mon.mnemonic.split(' ')} });
      } else {
        localStorage.setItem('mnemonic', '')
      }

      return () => {
        if (window.updateMnemonic) {
          clearInterval(window.updateMnemonic)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (library) {
      // 监听网络变化
      library.provider.on("networkChanged", network => {
        // if (window.location.href.indexOf('/crossChain') == -1) {
        //   if (document.visibilityState == 'visible') {
        //     window.location.reload();
        //   }
        // }
      });

      // 切换账号刷新
      library.provider.on('accountsChanged', function (accounts) {
        if (document.visibilityState == 'visible') {
          dispatch({ type: SET_MNEMONIC.type, payload: {} })
          localStorage.setItem('needSecondary', '')
          localStorage.setItem('mnemonic', '')
          // debugger
          window.location.reload();
        }

        // loginSign(accounts[0])
      })
    }
  }, [library])

  // useEffect(() => {
  //   if (window.ethereum) {

  //     // 监听网络变化
  //     window.ethereum.on("networkChanged", network => {
  //       // if (window.location.href.indexOf('/crossChain') == -1) {
  //       //   if (document.visibilityState == 'visible') {
  //       //     window.location.reload();
  //       //   }
  //       // }
  //     });

  //     // 切换账号刷新
  //     window.ethereum.on('accountsChanged', function (accounts) {
  //       if (document.visibilityState == 'visible') {
  //         window.location.reload();
  //         dispatch({ type: SET_MNEMONIC.type, payload: {} })
  //         localStorage.setItem('needSecondary', '')
  //       }

  //       // loginSign(accounts[0])
  //     })
  //   }
  // }, [])

  useSocket(account)
  // if (tokens.length == 0) {
  //   return <Suspense fallback={<div style={{ backgroundColor: localStorage.getItem('app_setting_theme') == 'light' ? '#fff' : '#16141A' }}>loading....</div>}>
  //   </Suspense>
  // }
  return (
    <Suspense fallback={<div style={{ backgroundColor: localStorage.getItem('app_setting_theme') == 'light' ? '#fff' : '#16141A' }}>loading....</div>}>
      <BrowserRouter>
        <div className="App" id="App">
          {/* <BrowserNotification /> */}
          <Nav />

          <div className="content" id="content">

            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/trade/:coin?" component={tokens.length == 0 ? TransactionNone : TransactionParams}/>
              <Route exact path="/earn/stacking" component={tokens.length == 0 ? TransactionNone : Earn} />
              <Route exact path="/ecosystem" component={Ecology} />
              <Route path="/referrals/:code?" component={RecommendShare}/>
              <Route exact path="/recommendSetting" component={RecommendSetting} />
              {/* <Route exact path="/fund/trade" component={FundTransaction} /> */}
              <Route exact path="/aboutFundPool" component={AboutFundPool} />
              <Route exact path="/earnMy" component={EarnMy} />
              <Route exact path="/createChallenge" component={CreateChallenge} />
              <Route exact path="/createFund" component={CreateFund} />
              <Route exact path="/challengeDetail" component={ChallengeDetail} />
              <Route exact path="/challenge" component={Challenge} />
              <Route exact path="/crossChain" component={tokens.length == 0 ? TransactionNone : CrossChain} />
              <Route exact path="/event-hub/trading-competition" component={TradingCompetition} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/event-hub/bonus-hunter" component={BountyHunter} />
              <Route exact path="/event-hub/bug-bounty" component={BugCompetition} />

              <Route exact path="/zh_cn" component={Home} />
              <Route path="/zh_cn/trade/:coin?" component={tokens.length == 0 ? TransactionNone : TransactionParams}/>
              <Route exact path="/zh_cn/earn/stacking" component={tokens.length == 0 ? TransactionNone : Earn} />
              <Route exact path="/zh_cn/ecosystem" component={Ecology} />
              <Route path="/zh_cn/referrals/:code?" component={RecommendShare}/>
              <Route exact path="/zh_cn/recommendSetting" component={RecommendSetting} />
              {/* <Route exact path="/zh_cn/fund/trade" component={FundTransaction} /> */}
              <Route exact path="/zh_cn/aboutFundPool" component={AboutFundPool} />
              <Route exact path="/zh_cn/earnMy" component={EarnMy} />
              <Route exact path="/zh_cn/createChallenge" component={CreateChallenge} />
              <Route exact path="/zh_cn/createFund" component={CreateFund} />
              <Route exact path="/zh_cn/challengeDetail" component={ChallengeDetail} />
              <Route exact path="/zh_cn/challenge" component={Challenge} />
              <Route exact path="/zh_cn/crossChain" component={tokens.length == 0 ? TransactionNone : CrossChain} />
              <Route exact path="/zh_cn/event-hub/trading-competition" component={TradingCompetition} />
              <Route exact path="/zh_cn/dashboard" component={Dashboard} />
              <Route exact path="/zh_cn/event-hub/bonus-hunter" component={BountyHunter} />
              <Route exact path="/zh_cn/event-hub/bug-bounty" component={BugCompetition} />
              <Redirect to="/" />
            </Switch>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
